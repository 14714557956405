<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />
      <CCard v-else>
        <CCardHeader> الحجوزات </CCardHeader>
        <CCardBody>
          <CRow class="mt-3">
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="موبايل المحامي" type="number" autocomplete="phone" v-model.trim="search.lawyer_phone" />
            </CCol>
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="موبايل العميل" type="number" autocomplete="phone" v-model.trim="search.client_phone" />
            </CCol>
            <CCol sm="12" md="3">
              <CButton @click="searchForm" type="button" class="btn-sm" color="primary">
                <CIcon name="cil-search" /> بحث
              </CButton>
              <CButton @click="resetForm" type="button" class=" mx-2" color="danger">
                تفريغ
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">اسم المحامي</th>
                  <th scope="col">موبايل المحامي</th>
                  <th scope="col">اسم العميل</th>
                  <th scope="col">موبايل العميل</th>
                  <th scope="col">المكتب</th>
                  <th scope="col">السعر الاساسي</th>
                  <th scope="col">السعر النهائي</th>
                  <th scope="col">ميعاد الحجز</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.lawyer_name }}</td>
                  <td>{{ item.lawyer_phone }}</td>
                  <td>{{ item.client_name }}</td>
                  <td>{{ item.client_phone}}</td>
                  <td>{{ item.office_name }}</td>
                  <td>{{ item.sub_price }}</td>
                  <td>{{ item.final_price }}</td>
                  <td>{{ item.booking_at }}</td>
                  <td><b>{{ item.status_name }}</b></td>

                  <td>
                    <CButton
                      color="info"
                      @click="
                        $router.push({
                          path: `bookings/${item.id}`,
                          params: { booking: item },
                        })
                      "
                    >
                      <CIcon name="cil-low-vision"></CIcon>
                    </CButton>
                    <span v-if="item.status == 2">
                        <CButton  class="mx-2" color="success" @click="updateStatus('confirm', item.id)">
                          تأكيد
                        </CButton>
                        <CButton color="danger" class="mx-2" @click="updateStatus('cancel', item.id)">
                          الغاء
                        </CButton>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue';

export default {
  name: "Bookings",
  data() {
    return {
      items: [],
      activePage: 1,
      currentPage: 1,
      allPages: 10,
      search: {
        lawyer_phone: null, 
        client_phone: null
      }
    };
  },
  watch: {
    currentPage: {
      handler: function (val, oldVal) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1, data = null) {
      this.toggleLoadSpinner();
      let url = `bookings?page=${page}`;
      if (data) {
        url += data;
      }
      this.$http
        .get(url)
        .then((res) => {
          // handle success
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },

    searchForm() {

      var query = "";
      if (this.search.lawyer_phone) {
        query += `&lawyer_phone=${this.search.lawyer_phone}`;
      }
      if (this.search.client_phone) {
        query += `&client_phone=${this.search.client_phone}`;
      }

      this.fetchData(1, query);
    },
    
    resetForm() {
      this.search.lawyer_phone = null;
      this.search.client_phone = null;
    },

    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },

    updateStatus(action, id) {
      this.toggleLoadSpinner();

      this.$http
        .put(`/bookings/${id}/${action}`)
        .then((res) => {
          const index = this.items.findIndex(object => {
            return object.id === id;
          });
          Vue.set(this.items, index, res.data.data);
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          // handle error
          this.toggleLoadSpinner();
        });
    }
  },
};
</script>